@import '../../../../../scss/shared/settings.scss';

.big-header-default {
    background-color: rgba(var(--brand-offset-rgb), 0.08);

    &::after {
        display: none;
    }

    h1,
    h4,
    p {
        text-shadow: none;
        color: $dark-grey;
    }

    p {
        opacity: 0.5;
    }

    .image-holder {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        overflow: hidden;

        .default-header-image {
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            width: 1416px;
            height: 441px;
        }

        @include respond-to(mobile) {
            .clouds,
            .park,
            .retailer {
                position: absolute;
            }

            .clouds {
                top: 40px;
                left: -32px;
                width: 578px;
                height: 64px;
            }

            .park {
                bottom: 16px;
                left: -28px;
                width: 88px;
                height: 79px;
            }

            .retailer {
                right: -48px;
                bottom: 26px;
                width: 125px;
                height: 146px;
            }
        }
    }
}